import $ from 'jquery';
import datepickerFactory from 'jquery-datepicker';
import datepickerJAFactory from './jquery.ui.datepicker-en-US';

// Pass your jquery instance to kickkoff datepicker factory
datepickerFactory($);
datepickerJAFactory($);

$(function() {

    //Delivery Date datepicker
    let datepicker = $('#delivery_date');
    let datpickerOptions = TG.delivery;
    let unAvailableDates = getUnavailableDays(datpickerOptions.custom_days);

    //Creates earliest selectable date
    const dateToday     = new Date()
    const dateTomorrow  = new Date(dateToday)
    dateTomorrow.setDate(dateTomorrow.getDate() + 1);

    //Retrieves the custom unavailable days in array format
    function getUnavailableDays(data){
        let availableDays = data.split(',');
        return availableDays;
    }

    function getDayOptions(date) {
        let day = date.getDay();
        let formattedDate = pad((date.getMonth()+1)) + "/" + pad(date.getDate()) + "/" + date.getFullYear();

        //adds leading zero
        function pad(d) {
            return (d < 10) ? '0' + d.toString() : d.toString();
        }

        if ((day === 0) && (datpickerOptions.sunday == false)) {
            return [false];

        } else if ((day === 1) && (datpickerOptions.monday == false)){
            return [false];

        } else if ((day === 2) && (datpickerOptions.tuesday == false)){
            return [false];

        } else if ((day === 3) && (datpickerOptions.wednesday == false)){
            return [false];

        } else if ((day === 4) && (datpickerOptions.thursday == false)){
            return [false];

        } else if ((day === 5) && (datpickerOptions.friday == false)){
            return [false];

        } else if ((day === 6) && (datpickerOptions.saturday == false)){
            return [false];

        } else {
            if ($.inArray(formattedDate, unAvailableDates ) > -1 ) {
                return [false];
            }
            return [true];
        }
    }

    datepicker.datepicker({
        beforeShowDay: getDayOptions,
        minDate: dateTomorrow,
    });

    $("form.checkout.woocommerce-checkout").on('submit', function() {
        $('#place_order').attr('disabled', 'disabled');
    });

});

jQuery(document.body).on( 'checkout_error', function() {
    $('#place_order').removeAttr('disabled');
});
