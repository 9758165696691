import { EventBuilder } from './_jquery.ga-lib'
//Add your Google Analytics Events below
//ga('send', 'event', [eventCategory], [eventAction], [eventLabel], [eventValue], [fieldsObject]);

//CATEGORY: Contact

jQuery(($) => {
  const gaDebug = !!TG.gaDebug
  const eventBuilder = new EventBuilder(gaDebug)

  eventBuilder.bind('a[href^="mailto"], a[href^="tel:"]')
  eventBuilder.dynamicBind('form', 'gform_confirmation_loaded')
})
