jQuery(($) => {
    const NAV = {
      classes: {
        Scrolling: 'scrolling',
        Fixed: 'fixed-top',
        NavFixed: 'nav-fixed'
      },
      elements: {
        nav: jQuery('#navbar--primary'),
        header: jQuery('#header--site'),
        body: jQuery('body')
      },
      isScrolled: () => {
        let scrolled = false
        if ($(window).scrollTop() > NAV.elements.header.height()) {
          scrolled = true
        }
        return scrolled
      }
    }
  
    let $primaryNav = $('#navbar--primary')
    let $documentBody = $('body')
  
    $(window).on('load scroll', () => {
      let currentOffset = $(window).scrollTop()
      let navbarHeight = $primaryNav.height()
  
      if (NAV.isScrolled()) {
        $primaryNav.addClass(NAV.classes.Scrolling)
      }
  
      if (currentOffset > navbarHeight * 3) {
        $primaryNav.addClass('fixed-top')
        $documentBody.css('paddingTop', navbarHeight)
      } else if (currentOffset < navbarHeight) {
        $primaryNav.removeClass('fixed-top').removeClass('scrolling')
        $documentBody.css('paddingTop', '')
      }
    })
  
    //Navigation drawer toggle
    let $drawerNavigation = $('.navbar--wrapper')
    let $drawerToggle = $('button.navbar-toggle')
    let $body = $('body')
  
    $drawerToggle.on('click', () => {
      $drawerNavigation.toggleClass('open')
      $body.toggleClass('nav-open')
    })
    $(window).on('resize', () => {
      if ($drawerNavigation.hasClass('open')) {
        $drawerNavigation.removeClass('open')
      }
      if ($body.hasClass('nav-open')) {
        $body.removeClass('nav-open')
      }
    })
    $(window).on('orientationchange', () => {
      if ($drawerNavigation.hasClass('open')) {
        $drawerNavigation.removeClass('open')
      }
      if ($body.hasClass('nav-open')) {
        $body.removeClass('nav-open')
      }
    })
  
    //Navigation dropdown navigation
    let $parentElements = $('.dropdown-toggle')
    $parentElements.each((index, element) => {
      $(element).on('click', () => {
        let $trigger = $(element)
        let $allDropdowns = $('.navbar--menu').find('.dropdown-menu')
        let $dropdownMenu = $(element).siblings('ul.dropdown-menu')
        let $dropdownMenuItems = $dropdownMenu.children()
        let $dropdownMenuHeight = 0
  
        $allDropdowns.css('height', 0)
  
        if ($dropdownMenu.height() <= 1) {
          $dropdownMenuItems.each(() => {
            $dropdownMenuHeight += parseInt($(element).outerHeight(), 10)
          })
          $dropdownMenu.css('height', $dropdownMenuHeight)
        } else {
          $dropdownMenu.css('height', 0)
        }
      })
    })
  })
  